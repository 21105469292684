<template>
	<div v-if="loading">
		<loading-spinner/>
	</div>
  <div class="px-3" v-else>
    <div class="row" >
      <div class="col-12 mt-5 col-sm-6">
        <h5 class="text-center text-bold">
          {{$t('notamView.notamKey')}}
        </h5>
        <p class="text-center mt-3">
          {{notam.notamKey}}
        </p>
      </div>
      <div class="col-12 mt-5 col-sm-6 ">
        <h5 class="text-center text-bold">
          {{$t('notamView.message')}}
        </h5>
        <p class="text-center mt-3">
          {{notam.message}}
        </p>
      </div>
    </div>
    <div class="row ">
      <div class="col-12 mt-5 col-sm-6">
        <h5 class="text-center text-bold">
          {{$t('notamView.startDate')}}
        </h5>
        <p class="text-center mt-3">
          {{notam.startDate}}
        </p>
      </div>
      <div class="col-12 mt-5 col-sm-6 ">
        <h5 class="text-center text-bold">
          {{$t('notamView.endDate')}}
        </h5>
        <p class="text-center mt-3">
          {{notam.endDate}}
        </p>
      </div>
    </div>
    <div class="row ">
      <div class="col-12 mt-5 col-sm-6 ">
        <h5 class="text-center text-bold">
          {{$t('notamView.entity')}}
        </h5>
        <p class="text-center mt-3">
          {{notam.endDate}}
        </p>
      </div>
      <div class="col-12 mt-5 col-sm-6 ">
        <h5 class="text-center text-bold">
          {{$t('notamView.status')}}
        </h5>
        <p class="text-center mt-3">
          {{notam.endDate}}
        </p>
      </div>
    </div>
    <div class="row ">
      <div class="col-12 mt-5 col-sm-6 ">
        <h5 class="text-center text-bold">
          {{$t('notamView.startHeight')}}
        </h5>
        <p class="text-center mt-3">
          {{notam.startHeight}} m
        </p>
      </div>
      <div class="col-12 mt-5 col-sm-6 ">
        <h5 class="text-center text-bold">
          {{$t('notamView.endHeight')}}
        </h5>
        <p class="text-center mt-3">
          {{notam.endHeight || "-"}} m
        </p>
      </div>
    </div>
    <div class="row ">
      <div class="col-12 mt-5 col-sm-6 ">
        <h5 class="text-center text-bold">
          {{$t('notamView.qCode')}}
        </h5>
        <p class="text-center mt-3">
          {{notam.qCode}}
        </p>
      </div>
      <div class="col-12 mt-5 col-sm-6 ">
        <h5 class="text-center text-bold">
          {{$t('notamView.subarea')}}
        </h5>
        <p class="text-center mt-3">
          {{notam.subarea}}
        </p>
      </div>
    </div>
    <div class="row mb-5 ">
      <div class="col-12 mt-5 col-sm-4 ">
        <h5 class="text-center text-bold">
          {{$t('notamView.state')}}
        </h5>
        <p class="text-center mt-3">
          {{notam.state}}
        </p>
      </div>
      <div class="col-12 mt-5 col-sm-4 ">
        <h5 class="text-center text-bold">
          {{$t('notamView.subject')}}
        </h5>
        <p class="text-center mt-3">
          {{notam.subject}}
        </p>
      </div>
      <div class="col-12 mt-5 col-sm-4 ">
        <h5 class="text-center text-bold">
          {{$t('notamView.modifier')}}
        </h5>
        <p class="text-center mt-3">
          {{notam.modifier}}
        </p>
      </div>
    </div>
  </div>
</template>

<script>

import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default {
  name: 'NotamObserverView',
	components: { LoadingSpinner },
  data() {
    return {
      notam: null,
			loading: true,
    };
  },
  props: {
    app: Object
  },
  async mounted() {
    this.id = this.$route.params.id;
		this.notam = await this.$rest.getNotam(this.id);
		if(!this.notam) {
			await this.$router.replace({ name: 'notamList' });
			return;
		}

		window.newEvent("NOTAM", {
			id: `${this.notam.id}`,
			key: this.notam.notamKey,
		})

		this.loading = false;
	},
}
</script>

<style>
</style>
